angular.module('pictadayFirebaseApp', [
    'ui.router',
    'ngMaterial',
    'ngMessages',
    'ngAnimate',
    'angularFileUpload',
    'cp.ng.fix-image-orientation',
    'xeditable'])

.config(['$stateProvider', '$urlRouterProvider', '$locationProvider', '$mdThemingProvider',
    function($stateProvider, $urlRouterProvider, $locationProvider, $mdThemingProvider) {
        $locationProvider.html5Mode(true);

        $urlRouterProvider.otherwise('/');

        $stateProvider
            .state('auth', {
                abstract: true, // this state can not be activated itself and must be a parent
                template: '<ui-view/>', // needed in order to inject the children into the view
                resolve: {
                    user: ['User', '$q', function (User, $q) {
                        var d = $q.defer();
                        if (User.checkAuthenticated()) {
                            // I also provide the user for child controllers
                            d.resolve(User.user);
                        } else {
                            // here the rejection
                            d.reject('not logged in');
                        }
                        return d.promise;
                    }]
                }
            })
            .state('home', {
                url: '/',
                templateUrl: 'components/home.html',
                controller: 'HomeCtrl',
                controllerAs: 'vm',
                parent: 'auth'
            })
            .state('preferences', {
                url: '/preferences',
                templateUrl: 'components/preferences.html',
                controller: 'PrefsCtrl',
                controllerAs: 'vm',
                parent: 'auth'
            })
            .state('album', {
                url: '/album/:alb_id',
                templateUrl: 'components/album.html',
                controller: 'AlbumCtrl',
                controllerAs: 'vm',
                parent: 'auth'
            })
            .state('signup', {
                url: '/signup',
                templateUrl: 'components/signup.html',
                controller: 'LoginCtrl',
                controllerAs: 'vm'
            })
            .state('forgot', {
                url: '/forgot',
                templateUrl: 'components/forgot.html',
                controller: 'LoginCtrl',
                controllerAs: 'vm'
            })
            .state('login', {
                url: '/login',
                templateUrl: 'components/login.html',
                controller: 'LoginCtrl',
                controllerAs: 'vm'
            });

        // Initialize Firebase
        var config = {
            apiKey: "AIzaSyC7VhtXymjteXVi94ItLq5ikWHrgcq1Kjg",
            authDomain: "pictaday-d0fe1.firebaseapp.com",
            databaseURL: "https://pictaday-d0fe1.firebaseio.com",
            storageBucket: "pictaday-d0fe1.appspot.com",
            messagingSenderId: "511422328016"
        };
        firebase.initializeApp(config);

        firebase.googleProvider = new firebase.auth.GoogleAuthProvider();
        firebase.facebookProvider = new firebase.auth.FacebookAuthProvider();
        firebase.twitterProvider = new firebase.auth.TwitterAuthProvider();

        // Configure a dark theme with primary foreground yellow

        $mdThemingProvider.theme('docs-dark', 'default')
            .primaryPalette('yellow')
            .dark();

    }])
    .run(['$log', '$rootScope', '$state', 'User', '$timeout', 'editableOptions', 'editableThemes',
            function ($log, $rootScope, $state, User, $timeout, editableOptions, editableThemes) {
        editableOptions.theme = 'default'; // bootstrap3 theme. Can be also 'bs2', 'default'
        editableThemes['default'].submitTpl = '<md-button class="md-raised md-primary" type="submit">save</md-button>';
        editableThemes['default'].cancelTpl = '<md-button type="submit">cancel</md-button>';

        firebase.auth().onAuthStateChanged(function(user) {
            if (user) {
                // User is signed in.
                $log.info("onAuthStateChanged: logged in!");
                User.setUser(user);
                $state.go('home', {}, {reload: true});
            } else {
                // No user is signed in.
                $log.info("onAuthStateChanged: not logged in!");
                $state.go('login');
            }
        });
        $rootScope.$on('$stateChangeError', function (event, toState, toParams, fromState, fromParams, error) {
            $log.info('state change error');
            //here you can go to whatever state you want, and you also have a lot of information to save if needed
            $timeout(function () {
                $state.go('login');
            });
        });
    }])
    .controller('HomeCtrl', ['$scope', '$log', '$window', '$mdDialog', '$mdToast', 'User', 'FileUploader',
            function ($scope, $log, $window, $mdDialog, $mdToast, User, FileUploader) {
        var vm = this;

        vm.albums  = [];
        vm.receiving = [];
        vm.loading = true;
        vm.loadingRecv = true;

        vm.loadAlbums = loadAlbums;
        function loadAlbums()
        {
            vm.albums = [];
            User.getAlbums().then(function (snap) {
                snap.forEach(function (elem) {
                    vm.albums.push({
                        alb_id:        elem.key,
                        name:          elem.val().name,
                        recipients:    elem.val().recipients,
                        send_time:     elem.val().send_time,
                        sender_email:  elem.val().sender_email,
                        sender_name:   elem.val().sender_name,
                        sender_tz:     elem.val().sender_tz
                    });
                });
                vm.loading = false;
                $scope.$apply();
                vm.albums.forEach(function (alb) {
                    alb.featured = [];
                    User.getRecentPhotos(alb.alb_id, 4).then(function (snap) {
                        $log.info('got photos');
                        snap.forEach(function (elem) {
                            $log.info(elem.val().link);
                            alb.featured.unshift(elem.val().link);
                        });
                        $scope.$apply();
                    });
                });
            });
            vm.receiving = [];
            User.getReceiving().then(function (snap) {
                snap.forEach(function (elem) {
                    vm.receiving.push({
                        alb_id:        elem.key,
                        name:          elem.val().name,
                        sender_email:  elem.val().sender_email,
                        sender_name:   elem.val().sender_name
                    });
                });
                vm.loadingRecv = false;
                vm.receiving.forEach(function (alb) {
                    alb.featured = [];
                    User.getRecentPhotosRecv(alb.alb_id, 4).then(function (snap) {
                        $log.info('got photos for album:' + alb.alb_id);
                        snap.forEach(function (elem) {
                            $log.info(elem.val().link);
                            alb.featured.unshift(elem.val().link);
                        });
                    });
                });
            });
        }
        loadAlbums();

        vm.showSpinner = showSpinner;
        function showSpinner()
        {
            return vm.loading;
        }

        vm.cancelCreateAlbumDialog = cancelCreateAlbumDialog;
        function cancelCreateAlbumDialog()
        {
            $mdDialog.cancel();
        }

        vm.saveCreateAlbumDialog = saveCreateAlbumDialog;
        function saveCreateAlbumDialog (ctrl)
        {
            var exists = false;
            vm.albums.forEach(function (elem) {
                if (elem.name === ctrl.name && elem.alb_id !== ctrl.alb_id) {
                    exists = true;
                }
            });
            if (exists) {
                var message = "Not created.  Duplicate album name not allowed";
                $mdToast.show({
                    template : '<md-toast id="language-message" layout="column" layout-align="center start" style="min-height:60px">' +
                        '<div class="md-toast-content slim-toast">' + message + '</div></md-toast>',
                    hideDelay: 5000,
                    position : 'top right',
                    parent   : '#create-dialog'
                });
                return;
            }
            $mdDialog.hide(ctrl);
        }

        vm.addRecipient = addRecipient;
        function addRecipient (recip)
        {
            var exists = false;
            vm.recipients.forEach(function (elem) {
                if (elem === recip) {
                    exists = true;
                }
            });
            if (exists) {
                var message = "Not added.  Duplicate email not allowed";
                $mdToast.show({
                    template : '<md-toast id="language-message" layout="column" layout-align="center start" style="min-height:60px">' +
                        '<div class="md-toast-content slim-toast">' + message + '</div></md-toast>',
                    hideDelay: 5000,
                    position : 'top right',
                    parent   : '#create-dialog'
                });
                return;
            }
            vm.recipients.push(recip);
        }

        vm.removeRecipient = removeRecipient;
        function removeRecipient (recip)
        {
            var indexToRemove = -1;
            vm.recipients.forEach(function (elem, index) {
                if (elem === recip) {
                    indexToRemove = index;
                }
            });
            if (indexToRemove >= 0) {
                vm.recipients.splice(indexToRemove, 1);
            } else {
                var message = "An error occurred, email not found.";
                $mdToast.show({
                    template : '<md-toast id="language-message" layout="column" layout-align="center start" style="min-height:60px">' +
                        '<div class="md-toast-content slim-toast">' + message + '</div></md-toast>',
                    hideDelay: 5000,
                    position : 'top right',
                    parent   : '#create-dialog'
                });
            }
        }

        vm.createAlbum = createAlbum;
        function createAlbum(ev)
        {
            vm.recipients = [];
            $mdDialog.show({
                controller: function () {},
                controllerAs: 'vm',
                bindToController: true,
                locals: {
                    cancel:          vm.cancelCreateAlbumDialog,
                    save:            vm.saveCreateAlbumDialog,
                    recipients:      vm.recipients,
                    addRecipient:    vm.addRecipient,
                    removeRecipient: vm.removeRecipient,
                    send_time:       User.getDefaultSendTime(),
                    sender_email:    User.getDefaultEmail() || User.getEmail(),
                    sender_name:     User.getName(),
                    sender_tz:       User.getTimeZone(),
                    save_defaults:   true,
                    title:           "Create Album"
                },
                templateUrl: 'components/dialog-templates/create-album.tmpl.html',
                parent: angular.element(document.body),
                targetEvent: ev,
                clickOutsideToClose: true,
                fullscreen: $window.innerWidth < 600 // Only for -xs breakpoints.
            })
            .then(function(ctrl) {
                $log.info('You saved the dialog');
                User.createAlbum(ctrl.name, vm.recipients, ctrl.send_time, ctrl.sender_email, ctrl.sender_name, ctrl.sender_tz).then(function () {
                    loadAlbums();
                    if (ctrl.save_defaults) {
                        User.setProfile(ctrl.sender_name, ctrl.sender_email, ctrl.send_time, ctrl.sender_tz);
                    }
                });
            }, function() {
                $log.info('You cancelled the dialog.');
            });
        }

        vm.modifyAlbum = modifyAlbum;
        function modifyAlbum (ev, alb)
        {
            $log.info('modifyAlbum');
            $log.info(alb);

            vm.recipients = alb.recipients;
            $mdDialog.show({
                controller: function () {},
                controllerAs: 'vm',
                bindToController: true,
                locals: {
                    cancel:          vm.cancelCreateAlbumDialog,
                    save:            vm.saveCreateAlbumDialog,
                    recipients:      vm.recipients,
                    addRecipient:    vm.addRecipient,
                    removeRecipient: vm.removeRecipient,
                    title:           "Modify Album",
                    name:            alb.name,
                    alb_id:          alb.alb_id,
                    send_time:       alb.send_time,
                    sender_email:    alb.sender_email,
                    sender_name:     alb.sender_name,
                    sender_tz:       alb.sender_tz,
                    save_defaults:   true,
                },
                templateUrl: 'components/dialog-templates/create-album.tmpl.html',
                parent: angular.element(document.body),
                targetEvent: ev,
                clickOutsideToClose: true,
                fullscreen: $window.innerWidth < 600 // Only for -xs breakpoints.
            })
            .then(function(ctrl) {
                $log.info('You saved the dialog with name: ' + ctrl.name);
                User.modifyAlbum(ctrl.alb_id, ctrl.name, vm.recipients, ctrl.send_time,
                        ctrl.sender_email, ctrl.sender_name, ctrl.sender_tz).then(function () {
                    loadAlbums();
                    if (ctrl.save_defaults) {
                        User.setProfile(ctrl.sender_name, ctrl.sender_email, ctrl.send_time, ctrl.sender_tz);
                    }
                });
            }, function() {
                $log.info('You cancelled the dialog.');
            });
        }

        vm.hideToast = hideToast;
        function hideToast()
        {
            $log.info('hide toast');
            $mdToast.hide();
        }

        vm.confirmedDelete = confirmedDelete;
        function confirmedDelete (alb_id)
        {
            User.deleteAlbum(alb_id).then(function (result) {
                loadAlbums();
            });
            vm.hideToast();
        }

        vm.deleteAlbum = deleteAlbum;
        function deleteAlbum (ev, alb)
        {
            ev.stopPropagation();
            $log.info("deleteAlbum:" + alb.name);
            var message = 'Permanently delete album?<br />' +
                                '<md-button ng-click="vm.confirmedDelete(\''+ alb.alb_id +'\')">Yes</md-button><br />' +
                                '<md-button ng-click="vm.hideToast()">Dismiss</md-button>';

            $mdToast.show({
                template : '<md-toast id="language-message" layout="column" layout-align="center start"><div class="md-toast-content">' +
                    message + '</div></md-toast>',
                hideDelay: 0,
                position : 'top right',
                parent   : '#album-'+alb.alb_id,
                controller: function() {},
                controllerAs: 'vm',
                bindToController: true,
                locals: {
                   confirmedDelete: vm.confirmedDelete,
                   hideToast: vm.hideToast
                }
            });
        }

        vm.cancelAddPhotosDialog = cancelAddPhotosDialog;
        function cancelAddPhotosDialog()
        {
            $mdDialog.cancel();
        }

        vm.saveAddPhotosDialog = saveAddPhotosDialog;
        function saveAddPhotosDialog (ctrl)
        {
            $mdDialog.hide(ctrl);
        }

        function uploadItemOverride(value) {
            $log.info('override!');
            var uploader = this;
            var index = this.getIndexOfItem(value);
            var item = this.queue[index];
            var transport = this.isHTML5 ? '_xhrTransport' : '_iframeTransport';

            item._prepareToUploading();
            if(this.isUploading) return;

            this._onBeforeUploadItem(item);
            if (item.isCancel) return;

            item.isUploading = true;
            this.isUploading = true;
            //this[transport](item);

            User.uploadFile(uploader, item).then(function() {
                $log.info('Uploaded a file!');
                uploader._onProgressItem(item, 100.0);
                uploader._onSuccessItem(item, undefined, 0, {});
                uploader._onCompleteItem(item, undefined, 0, {});
            }); // XXX what to do in case of failure? or cancel?
            $log.info(item);
            this._render();
        }

        vm.addPhotos = addPhotos;
        function addPhotos(ev, alb)
        {
            var uploader = $scope.uploader = new FileUploader({
                url: ''
            });

            uploader.filters.push({
                name: 'imageFilter',
                fn: function(item /*{File|FileLikeObject}*/, options) {
                    var type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
                    return '|jpg|png|jpeg|bmp|gif|'.indexOf(type) !== -1;
                }
            });

            uploader.uploadItem = uploadItemOverride;
            uploader.alb_id     = alb.alb_id;

            uploader.onWhenAddingFileFailed = function(item /*{File|FileLikeObject}*/, filter, options) {
                console.info('onWhenAddingFileFailed', item, filter, options);
            };
            uploader.onAfterAddingFile = function(fileItem) {
                console.info('onAfterAddingFile', fileItem);
            };
            uploader.onAfterAddingAll = function(addedFileItems) {
                console.info('onAfterAddingAll', addedFileItems);
            };
            uploader.onBeforeUploadItem = function(item) {
                console.info('onBeforeUploadItem', item);
            };
            uploader.onProgressItem = function(fileItem, progress) {
                console.info('onProgressItem', fileItem, progress);
            };
            uploader.onProgressAll = function(progress) {
                console.info('onProgressAll', progress);
            };
            uploader.onSuccessItem = function(fileItem, response, status, headers) {
                console.info('onSuccessItem', fileItem, response, status, headers);
            };
            uploader.onErrorItem = function(fileItem, response, status, headers) {
                console.info('onErrorItem', fileItem, response, status, headers);
            };
            uploader.onCancelItem = function(fileItem, response, status, headers) {
                console.info('onCancelItem', fileItem, response, status, headers);
            };
            uploader.onCompleteItem = function(fileItem, response, status, headers) {
                console.info('onCompleteItem', fileItem, response, status, headers);
            };
            uploader.onCompleteAll = function() {
                console.info('onCompleteAll');
            };

            console.info('uploader', uploader);

            $mdDialog.show({
                controller: function () {},
                controllerAs: 'vm',
                bindToController: true,
                locals: {
                    cancel:          vm.cancelAddPhotosDialog,
                    save:            vm.saveAddPhotosDialog,
                    uploader:        uploader,
                    title:           "Add Photos"
                },
                templateUrl: 'components/dialog-templates/add-photos.tmpl.html',
                parent: angular.element(document.body),
                targetEvent: ev,
                clickOutsideToClose: true,
                fullscreen: $window.innerWidth < 600 // Only for -xs breakpoints.
            })
            .then(function(ctrl) {
                $log.info('You saved the dialog');
                loadAlbums();
            }, function() {
                $log.info('You cancelled the dialog.');
            });
        }

        vm.getEmail = getEmail;
        function getEmail() {
            return User.getEmail();
        }

        vm.logout = logout;
        function logout () {
            User.logout();
        }
    }])
    .controller('PrefsCtrl', ['$scope', '$log', '$window', '$mdDialog', '$mdToast', 'User', 'FileUploader',
            function ($scope, $log, $window, $mdDialog, $mdToast, User, FileUploader) {
        var vm = this;

        vm.loadProfile = loadProfile;
        function loadProfile()
        {
            User.getProfile().then(function (profile) {
                $log.info('loaded profile');
                $log.info(profile);
                vm.profile = profile;
                if (profile.allow_email === undefined) {
                    vm.profile.allow_email = true;
                }
                if (profile.allow_pictaday === undefined) {
                    vm.profile.allow_pictaday = true;
                }
                if (profile.allow_newsletter === undefined) {
                    vm.profile.allow_newsletter = true;
                }
                if (profile.allow_notify === undefined) {
                    vm.profile.allow_notify = false;
                }
            });
        }
        loadProfile();

        vm.saveSender = saveSender;
        function saveSender() {
            User.setSenderProfile(vm.profile).then(function () {
                var message = "Settings saved successfully";
                $mdToast.show({
                    template : '<md-toast id="language-message" layout="column" layout-align="center start"><div class="md-toast-content">' +
                        message + '</div></md-toast>',
                    hideDelay: 4000,
                    position : 'top right',
                    parent   : '#sender-fields'
                });
            });
        }

        vm.saveRecip = saveRecip;
        function saveRecip() {
            User.setRecipProfile(vm.profile).then(function () {
                var message = "Settings saved successfully";
                $mdToast.show({
                    template : '<md-toast id="language-message" layout="column" layout-align="center start"><div class="md-toast-content">' +
                        message + '</div></md-toast>',
                    hideDelay: 4000,
                    position : 'top right',
                    parent   : '#recip-fields'
                });
            });
        }

        vm.allowEmailClick = allowEmailClick;
        function allowEmailClick() {
            if(!vm.profile.allow_email) {
                vm.profile.allow_pictaday   = false;
                vm.profile.allow_newsletter = false;
                $('#allow-pictaday').attr('disabled', true);
                $('#allow-news').attr('disabled', true);
            } else {
                vm.profile.allow_pictaday   = true;
                vm.profile.allow_newsletter = true;
                $('#allow-pictaday').attr('disabled', false);
                $('#allow-news').attr('disabled', false);
            }
        }

        vm.getEmail = getEmail;
        function getEmail() {
            return User.getEmail();
        }

        vm.logout = logout;
        function logout () {
            User.logout();
        }
    }])
    .controller('AlbumCtrl', ['$scope', '$log', '$window', '$stateParams', '$mdDialog', '$mdToast', 'User', 'FileUploader',
            function ($scope, $log, $window, $stateParams, $mdDialog, $mdToast, User, FileUploader) {
        var vm = this;

        vm.photos  = [];
        vm.loading = true;
        vm.alb_id  = $stateParams.alb_id;
        vm.today   = new Date();

        vm.loadPhotos = loadPhotos;
        function loadPhotos()
        {
            vm.photos = [];
            User.getPhotos(vm.alb_id).then(function (snap) {
                $log.info('got photos');
                snap.forEach(function (elem) {
                    vm.photos.unshift({
                        photo_id: elem.key,
                        link: elem.val().link,
                        date: new Date(elem.val().date) || '',
                        caption: elem.val().caption || '',
                        sent: elem.val().sent
                    });
                });
                vm.loading = false;
                $scope.$apply();
            });
        }
        loadPhotos();

        vm.updateCaption = updateCaption;
        function updateCaption(photo)
        {
            $log.info('updateCaption');
            $log.info(photo);
            User.setPhotoCaption(vm.alb_id, photo.photo_id, photo.caption);
        }

        vm.updateDate = updateDate;
        function updateDate(photo)
        {
            $log.info('updateDate');
            $log.info(photo);
            User.setPhotoDate(vm.alb_id, photo, photo.date).then(function () {
                loadPhotos();
            });
        }

        vm.hideToast = hideToast;
        function hideToast()
        {
            $log.info('hide toast');
            $mdToast.hide();
        }

        vm.confirmedDelete = confirmedDelete;
        function confirmedDelete (photo_id)
        {
            User.deletePhoto(vm.alb_id, photo_id).then(function (result) {
                loadPhotos();
            });
            vm.hideToast();
        }

        vm.deletePhoto = deletePhoto;
        function deletePhoto (ev, photo)
        {
            ev.stopPropagation();
            $log.info("deletePhoto");
            var message = 'Permanently delete photo?<br />' +
                                '<md-button ng-click="vm.confirmedDelete(\''+ photo.photo_id +'\')">Yes</md-button><br />' +
                                '<md-button ng-click="vm.hideToast()">Dismiss</md-button>';

            $mdToast.show({
                template : '<md-toast id="language-message" layout="column" layout-align="center start"><div class="md-toast-content">' +
                    message + '</div></md-toast>',
                hideDelay: 0,
                position : 'top right',
                parent   : '#photo-'+photo.photo_id,
                controller: function() {},
                controllerAs: 'vm',
                bindToController: true,
                locals: {
                   confirmedDelete: vm.confirmedDelete,
                   hideToast: vm.hideToast
                }
            });
        }

        vm.showSpinner = showSpinner;
        function showSpinner()
        {
            return vm.loading;
        }

        vm.cancelAddPhotosDialog = cancelAddPhotosDialog;
        function cancelAddPhotosDialog()
        {
            $mdDialog.cancel();
        }

        vm.saveAddPhotosDialog = saveAddPhotosDialog;
        function saveAddPhotosDialog (ctrl)
        {
            $mdDialog.hide(ctrl);
        }

        function uploadItemOverride(value) {
            $log.info('override!');
            var uploader = this;
            var index = this.getIndexOfItem(value);
            var item = this.queue[index];
            var transport = this.isHTML5 ? '_xhrTransport' : '_iframeTransport';

            item._prepareToUploading();
            if(this.isUploading) return;

            this._onBeforeUploadItem(item);
            if (item.isCancel) return;

            item.isUploading = true;
            this.isUploading = true;
            //this[transport](item);

            User.uploadFile(uploader, item).then(function() {
                $log.info('Uploaded a file!');
                uploader._onProgressItem(item, 100.0);
                uploader._onSuccessItem(item, undefined, 0, {});
                uploader._onCompleteItem(item, undefined, 0, {});
            }); // XXX what to do in case of failure? or cancel?
            $log.info(item);
            this._render();
        }

        vm.addPhotos = addPhotos;
        function addPhotos(ev)
        {
            var uploader = $scope.uploader = new FileUploader({
                url: ''
            });

            uploader.filters.push({
                name: 'imageFilter',
                fn: function(item /*{File|FileLikeObject}*/, options) {
                    var type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
                    return '|jpg|png|jpeg|bmp|gif|'.indexOf(type) !== -1;
                }
            });

            uploader.uploadItem = uploadItemOverride;
            uploader.alb_id     = vm.alb_id;

            uploader.onWhenAddingFileFailed = function(item /*{File|FileLikeObject}*/, filter, options) {
                console.info('onWhenAddingFileFailed', item, filter, options);
            };
            uploader.onAfterAddingFile = function(fileItem) {
                console.info('onAfterAddingFile', fileItem);
            };
            uploader.onAfterAddingAll = function(addedFileItems) {
                console.info('onAfterAddingAll', addedFileItems);
            };
            uploader.onBeforeUploadItem = function(item) {
                console.info('onBeforeUploadItem', item);
            };
            uploader.onProgressItem = function(fileItem, progress) {
                console.info('onProgressItem', fileItem, progress);
            };
            uploader.onProgressAll = function(progress) {
                console.info('onProgressAll', progress);
            };
            uploader.onSuccessItem = function(fileItem, response, status, headers) {
                console.info('onSuccessItem', fileItem, response, status, headers);
            };
            uploader.onErrorItem = function(fileItem, response, status, headers) {
                console.info('onErrorItem', fileItem, response, status, headers);
            };
            uploader.onCancelItem = function(fileItem, response, status, headers) {
                console.info('onCancelItem', fileItem, response, status, headers);
            };
            uploader.onCompleteItem = function(fileItem, response, status, headers) {
                console.info('onCompleteItem', fileItem, response, status, headers);
            };
            uploader.onCompleteAll = function() {
                console.info('onCompleteAll');
            };

            console.info('uploader', uploader);

            $mdDialog.show({
                controller: function () {},
                controllerAs: 'vm',
                bindToController: true,
                locals: {
                    cancel:          vm.cancelAddPhotosDialog,
                    save:            vm.saveAddPhotosDialog,
                    uploader:        uploader,
                    title:           "Add Photos"
                },
                templateUrl: 'components/dialog-templates/add-photos.tmpl.html',
                parent: angular.element(document.body),
                targetEvent: ev,
                clickOutsideToClose: true,
                fullscreen: $window.innerWidth < 600 // Only for -xs breakpoints.
            })
            .then(function(ctrl) {
                $log.info('You saved the dialog');
                loadPhotos();
            }, function() {
                $log.info('You cancelled the dialog.');
            });
        }

        vm.getEmail = getEmail;
        function getEmail() {
            return User.getEmail();
        }

        vm.logout = logout;
        function logout () {
            User.logout();
        }
    }])
    .controller('LoginCtrl', ['$rootScope', '$log', '$mdToast', '$state', 'User', function ($rootScope, $log, $mdToast, $state, User) {
        var vm = this;

        vm.login = login;
        function login () {
            $('#login-button').html("SIGNING IN");
            $('#login-button').prop("disabled", true);
            $log.info("sign in user to firebase...");
            firebase.auth().signInWithEmailAndPassword(vm.form.email, vm.form.password).catch(function(error) {
                // Handle Errors here.
                var errorCode = error.code;
                var errorMessage = error.message;
                var message;
                if (errorCode === 'auth/wrong-password') {
                    message = 'Wrong password.';
                } else {
                    message = errorMessage;
                }
                $mdToast.show({
                    template : '<md-toast id="language-message" layout="column" layout-align="center start"><div class="md-toast-content">' +
                        message + '</div></md-toast>',
                    hideDelay: 7000,
                    position : 'top right',
                    parent   : '#main'
                });
                $log.error(error);
            }).then(function (result) {
                $log.info("got a result");
                $log.info(result);
                if (result) {
                    $log.info("user logged in: " + result.email);
                    User.setUser(result);
                    // JUMP to the state change
                    $state.go('home');
                }
                $('#login-button').html("LOGIN");
                $('#login-button').prop("disabled", false);
            });
        }

        vm.signup = signup;
        function signup() {
            $log.info('go');
            $log.info(vm.form);

            $('#login-button').html("SIGNING UP");
            $('#login-button').prop("disabled", true);
            $log.info("sign up user via firebase...");
            firebase.auth().createUserWithEmailAndPassword(vm.form.email, vm.form.password).catch(function (error) {
                // Handle Errors here.
                var errorCode = error.code;
                var errorMessage = error.message;
                var message;
                if (errorCode === 'auth/email-already-in-use') {
                    message = 'Email already in use.';
                } else if (errorCode === 'auth/invalid-email') {
                    message = 'Email address is not valid';
                } else if (errorCode === 'auth/weak-password') {
                    message = 'Password does not meet complexity requirements';
                } else {
                    message = errorMessage;
                }
                $mdToast.show({
                    template: '<md-toast id="language-message" layout="column" layout-align="center start"><div class="md-toast-content">' +
                    message + '</div></md-toast>',
                    hideDelay: 7000,
                    position: 'top right',
                    parent: '#main'
                });
                $log.error(error);
            }).then(function (result) {
                $log.info("got a result");
                $log.info(result);
                if (result) {
                    $log.info("user signed up: " + result.email);
                    User.setUser(result);
                    // JUMP to the state change
                    $state.go('home');
                }
                $('#login-button').html("SIGN UP");
                $('#login-button').prop("disabled", false);
            });
        }

        vm.forgot = forgot;
        function forgot () {
            $log.info('forgot');
            $log.info(vm.form);

            $('#login-button').html("RESETTING");
            $('#login-button').prop("disabled", true);
            $log.info("send password reset request to firebase");
            firebase.auth().sendPasswordResetEmail(vm.form.email).then(function (success) {
                $log.info("success");
                $log.info(success);
                var message = 'Password reset complete';
                $mdToast.show({
                    template: '<md-toast id="language-message" layout="column" layout-align="center start"><div class="md-toast-content">' +
                    message + '</div></md-toast>',
                    hideDelay: 7000,
                    position: 'top right',
                    parent: '#main'
                });
            }).catch(function (error) {
                // Handle Errors here.
                var errorCode = error.code;
                var errorMessage = error.message;
                var message;
                if (errorCode === 'auth/invalid-email') {
                    message = 'Invalid email';
                } else if (errorCode === 'auth/user-not-found') {
                    message = 'User not found';
                } else {
                    message = errorMessage;
                }
                $mdToast.show({
                    template: '<md-toast id="language-message" layout="column" layout-align="center start"><div class="md-toast-content">' +
                    message + '</div></md-toast>',
                    hideDelay: 7000,
                    position: 'top right',
                    parent: '#main'
                });
                $log.error(error);
            }).then(function (result) {
                $('#login-button').html("RESET PASSWORD");
                $('#login-button').prop("disabled", false);
            });
        }

        vm.goGoogle = goGoogle;
        function goGoogle () {
            firebase.auth().signInWithPopup(firebase.googleProvider).then(function(result) {
                // This gives you a Google Access Token. You can use it to access the Google API.
                var token = result.credential.accessToken;
                User.setToken(token);
                // The signed-in user info.
                var user = result.user;
                User.setUser(user);
                $state.go('home');
            }).catch(function(error) {
                // Handle Errors here.
                var errorCode = error.code;
                var errorMessage = error.message;
                // The email of the user's account used.
                var email = error.email;
                // The firebase.auth.AuthCredential type that was used.
                var credential = error.credential;
                // ...
                var message;
                if (errorCode === 'auth/wrong-password') {
                    message = 'Wrong password.';
                } else {
                    message = errorMessage;
                }
                $mdToast.show({
                    template: '<md-toast id="language-message" layout="column" layout-align="center start"><div class="md-toast-content">' +
                    message + '</div></md-toast>',
                    hideDelay: 7000,
                    position: 'top right',
                    parent: '#main'
                });
                $log.error(error);
            });
        }

        vm.goFacebook = goFacebook;
        function goFacebook () {
            firebase.auth().signInWithPopup(firebase.facebookProvider).then(function(result) {
                // This gives you a Google Access Token. You can use it to access the Facebook API.
                var token = result.credential.accessToken;
                User.setToken(token);
                // The signed-in user info.
                var user = result.user;
                User.setUser(user);
                $state.go('home');
            }).catch(function(error) {
                // Handle Errors here.
                var errorCode = error.code;
                var errorMessage = error.message;
                // The email of the user's account used.
                var email = error.email;
                // The firebase.auth.AuthCredential type that was used.
                var credential = error.credential;
                // ...
                var message;
                if (errorCode === 'auth/wrong-password') {
                    message = 'Wrong password.';
                } else {
                    message = errorMessage;
                }
                $mdToast.show({
                    template: '<md-toast id="language-message" layout="column" layout-align="center start"><div class="md-toast-content">' +
                    message + '</div></md-toast>',
                    hideDelay: 7000,
                    position: 'top right',
                    parent: '#main'
                });
                $log.error(error);
            });
        }

        vm.goTwitter = goTwitter;
        function goTwitter () {
            firebase.auth().signInWithPopup(firebase.twitterProvider).then(function(result) {
                // This gives you a Google Access Token. You can use it to access the Twitter API.
                var token = result.credential.accessToken;
                User.setToken(token);
                // The signed-in user info.
                var user = result.user;
                User.setUser(user);
                $state.go('home');
            }).catch(function(error) {
                // Handle Errors here.
                var errorCode = error.code;
                var errorMessage = error.message;
                // The email of the user's account used.
                var email = error.email;
                // The firebase.auth.AuthCredential type that was used.
                var credential = error.credential;
                // ...
                var message;
                if (errorCode === 'auth/wrong-password') {
                    message = 'Wrong password.';
                } else {
                    message = errorMessage;
                }
                $mdToast.show({
                    template: '<md-toast id="language-message" layout="column" layout-align="center start"><div class="md-toast-content">' +
                    message + '</div></md-toast>',
                    hideDelay: 7000,
                    position: 'top right',
                    parent: '#main'
                });
                $log.error(error);
            });
        }
    }])
    .factory('User', ['$log', '$q',
    function ($log, $q) {
        var factory = {};

        // data
        factory.user    = undefined;
        factory.token   = undefined;
        factory.profile = {
            allow_email:      true,
            allow_pictaday:   true,
            allow_newsletter: true,
            allow_notify:     false
        };

        // methods
        factory.setUser = function (u) {
            factory.user = u;
            firebase.database().ref('profile/' + factory.user.uid).update({loginEmail: factory.user.email});
            firebase.database().ref('profile/' + factory.user.uid).once('value').then(function (snap) {
                factory.profile = snap.val() || {};
            });
        };

        factory.setToken = function (t) {
            factory.token = t;
        };

        factory.checkAuthenticated = function () {
            $log.info("checkAuthenticated");
            factory.user = firebase.auth().currentUser;
            if (factory.user) {
                $log.info("authenticated got a user!");
                return true;
            }
            $log.info("checkAuthenticated got no user");
            return false;
        };

        factory.getEmail = function () {
            if (factory.user) {
                return factory.user.email;
            }
            return '';
        };

        factory.getName = function () {
            return factory.profile.name || "";
        };

        factory.getDefaultSendTime = function () {
            return factory.profile.defaultSendTime || 8;
        };

        factory.getDefaultEmail = function () {
            return factory.profile.defaultEmail || null;
        };

        factory.getTimeZone = function () {
            return factory.profile.timeZone || -7;
        };

        factory.getProfile = function (name, email, defaultSendTime, timeZone) {
            var d = $q.defer();
            if (factory.user) {
                firebase.database().ref('profile/' + factory.user.uid).once("value")
                .then(function (snap) {
                    factory.profile = snap.val();
                    d.resolve(factory.profile);
                });
            } else {
                d.reject();
            }
            return d.promise;
        };

        factory.setProfile = function (name, email, defaultSendTime, timeZone) {
            if (factory.user) {
                factory.profile.name            = name;
                factory.profile.defaultEmail    = email;
                factory.profile.defaultSendTime = defaultSendTime;
                factory.profile.timeZone        = timeZone;
                return firebase.database().ref('profile/' + factory.user.uid).update(factory.profile);
            }
        };

        factory.setSenderProfile = function (profile) {
            var d = $q.defer();
            if (factory.user) {
                factory.profile.name            = profile.name;
                factory.profile.defaultEmail    = profile.defaultEmail;
                factory.profile.defaultSendTime = profile.defaultSendTime;
                factory.profile.timeZone        = profile.timeZone;
                firebase.database().ref('profile/' + factory.user.uid).update({
                    name:            profile.name,
                    defaultEmail:    profile.defaultEmail,
                    defaultSendTime: profile.defaultSendTime,
                    timeZone:        profile.timeZone
                }, function () {
                    d.resolve();
                });
            } else {
                d.reject();
            }
            return d.promise;
        };

        factory.setRecipProfile = function (profile) {
            var d = $q.defer();
            if (factory.user) {
                factory.profile.allow_email      = profile.allow_email;
                factory.profile.allow_pictaday   = profile.allow_pictaday;
                factory.profile.allow_newsletter = profile.allow_newsletter;
                factory.profile.allow_notify     = profile.allow_notify;
                firebase.database().ref('profile/' + factory.user.uid).update({
                    allow_email:      profile.allow_email,
                    allow_pictaday:   profile.allow_pictaday,
                    allow_newsletter: profile.allow_newsletter,
                    allow_notify:     profile.allow_notify
                }, function () {
                    d.resolve();
                });
            } else {
                d.reject();
            }
            return d.promise;
        };

        factory.logout = function () {
            factory.user = undefined;
            return firebase.auth().signOut();
        };

        factory.getAlbums = function () {
            if (factory.user) {
                return firebase.database().ref('album/' + factory.user.uid).once('value');
            }
        };

        factory.getReceiving = function () {
            var d = $q.defer();
            if (factory.user) {
                firebase.database().ref('inbox_album/' + factory.user.uid).once('value').then(function (snap) {
                    d.resolve(snap);
                });
            } else {
                d.reject();
            }
            return d.promise;
        };

        function guidGenerator() {
            var S4 = function() {
                return (((1+Math.random())*0x10000)|0).toString(16).substring(1);
            };
            return (S4()+S4()+"-"+S4()+"-"+S4()+"-"+S4()+"-"+S4()+S4()+S4());
        }

        factory.createAlbum = function (name, recipients, send_time, sender_email, sender_name, sender_tz) {
            if (factory.user) {
                var alb_id = guidGenerator();
                return firebase.database().ref('album/' + factory.user.uid + '/' + alb_id).set({
                    name:         name,
                    recipients:   recipients,
                    send_time:    send_time,
                    sender_email: sender_email,
                    sender_name:  sender_name,
                    sender_tz:    sender_tz
                });
            }
        };

        factory.modifyAlbum = function (alb_id, name, recipients, send_time, sender_email, sender_name, sender_tz) {
            if (factory.user) {
                return firebase.database().ref('album/' + factory.user.uid + '/' + alb_id).update({
                    name:         name,
                    recipients:   recipients,
                    send_time:    send_time,
                    sender_email: sender_email,
                    sender_name:  sender_name,
                    sender_tz:    sender_tz
                });
            }
        };

        factory.deleteAlbum = function (alb_id) {
            if (factory.user) {
                return firebase.database().ref('album/' + factory.user.uid + '/' + alb_id).remove();
            }
        };

        factory.addPhotoToAlbum = function (alb_id, photo_id, link) {
            var d     = $q.defer();
            if (factory.user) {
                var now   = new Date();
                var ms_per_day = 24*60*60*1000;
                var today_in_ms = new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate()).getTime();
                var today = Math.floor(today_in_ms / ms_per_day);
                var last;
                var stamp;
                var ref = firebase.database().ref('album/' + factory.user.uid + '/' + alb_id + '/photos/');
                ref.orderByChild("date").once('value').then(function (snap) {
                    snap.forEach(function (elem) {
                        $log.info("date: " + elem.val().date + " today_in_ms: " + today_in_ms + " stamp: " + stamp + " today: " + today);
                        if (elem.val().date && elem.val().date > today_in_ms && !stamp) {
                            var date = elem.val().date / ms_per_day;
                            // we have a date on this element AND it's from after today
                            if (!last && (date - today) > 1) {
                                stamp = (today + 1) * ms_per_day;
                                $log.info("set stamp 1 " + stamp);
                            }

                            if ((date - last) > 1) {
                                // skipped a day!
                                stamp = (last - 1) * ms_per_day;
                                $log.info("set stamp 2 " + stamp);
                            }

                            last = date;
                            $log.info("set last " + last);

                        } else {
                            // either no date OR date is before today OR we set the stamp already
                        }
                    });
                    if (!stamp && !last) {
                        stamp = (today + 1) * ms_per_day;
                        $log.info("set stamp 3 " + stamp);
                    }
                    if (!stamp) {
                        stamp = (Math.max(today, last) + 1) * ms_per_day;
                        $log.info("set stamp 4 " + stamp);
                    }
                    firebase.database().ref('album/' + factory.user.uid + '/' + alb_id + '/photos/' + photo_id).set({
                        link: link,
                        date: stamp,
                        sent: false
                    }).then(function () {
                        d.resolve();
                    });
                });
            } else {
                d.reject();
            }
            return d.promise;
        };

        factory.getRecentPhotos = function (alb_id, num) {
            if (factory.user) {
                return firebase.database().ref('album/' + factory.user.uid + '/' + alb_id + '/photos/')
                    .orderByChild('date').limitToLast(num).once('value');
            }
        };

        factory.getRecentPhotosRecv = function (alb_id, num) {
            var d = $q.defer();
            if (factory.user) {
                firebase.database().ref('inbox_photo/' + factory.user.uid + '/' + alb_id + '/photos/')
                    .orderByChild('date').limitToLast(num).once('value').then(function (snap) {
                        d.resolve(snap);
                    });
            } else {
                d.reject();
            }
            return d.promise;
        };

        factory.getPhotos = function (alb_id) {
            if (factory.user) {
                return firebase.database().ref('album/' + factory.user.uid + '/' + alb_id + '/photos/')
                    .orderByChild('date').once('value');
            }
        };

        factory.setPhotoCaption = function (alb_id, photo_id, caption) {
            if (factory.user) {
                firebase.database().ref('album/' + factory.user.uid + '/' + alb_id + '/photos/' + photo_id).update({
                    caption: caption
                });
            }
        };

        factory.setPhotoDate = function (alb_id, photo, date) {
            var d = $q.defer();
            var photo_id = photo.photo_id;
            var stamp = date.getTime();
            var ms_per_day = 24*60*60*1000;
            if (factory.user) {
                var multiUpdate = {};
                multiUpdate[photo_id] = {date: stamp, caption: photo.caption || '', link: photo.link, sent: photo.sent};
                // first get all the photos in this album
                var ref = firebase.database().ref('album/' + factory.user.uid + '/' + alb_id + '/photos/');
                ref.orderByChild('date').once('value').then(function (snap) {
                    var photos = [];
                    var foundDate = false;
                    snap.forEach(function (elem) {
                        if (elem.val().date === stamp && elem.key !== photo_id) {
                            foundDate = true;
                        }

                        photos.push({photo_id: elem.key, date: elem.val().date, caption: elem.val().caption, link: elem.val().link, sent: elem.val().sent});
                    });

                    if (foundDate) {
                        // need to push things around a bit to make room for this date
                        $log.info(photos);
                        for (var i = 0; i < photos.length; i++) {
                            if (!photos[i].sent && photos[i].date >= stamp && photos[i].photo_id !== photo_id) {
                                $log.info('shifting photo idx ' + i);
                                multiUpdate[photos[i].photo_id] = {
                                    date: photos[i].date + ms_per_day,
                                    caption: photos[i].caption || '',
                                    link: photos[i].link,
                                    sent: photos[i].sent
                                };
                            }
                            if (photos[i].date >= stamp && photos[i+1] && photos[i+1].date > photos[i].date + ms_per_day) {
                                // there's a gap of bigger than 1 day, so GTFO
                                break;
                            }
                        }
                    }

                    $log.info(multiUpdate);

                    // finally, update this photo
                    firebase.database().ref('album/' + factory.user.uid + '/' + alb_id + '/photos/').update(multiUpdate).then(function () {
                        d.resolve();
                    });
                });
            } else {
                d.reject();
            }
            return d.promise;
        };

        factory.deletePhoto = function (alb_id, photo_id) {
            if (factory.user) {
                return firebase.database().ref('album/' + factory.user.uid + '/' + alb_id + '/photos/' + photo_id).remove();
            }
        };

        factory.uploadFile = function (uploader, item) {
            var d          = $q.defer();
            var storageRef = firebase.storage().ref();
            var photo_id   = guidGenerator();
            var ref        = storageRef.child('photos/' + photo_id);
            var uploadTask = ref.put(item._file);

            // Listen for state changes, errors, and completion of the upload.
            uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, function(snapshot) {
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                uploader._onProgressItem(item, progress);
                console.log('Upload is ' + progress + '% done');
                switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');
                    break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                    console.log('Upload is running');
                    break;
                }
            }, function(error) {
                switch (error.code) {
                case 'storage/unauthorized':
                    // User doesn't have permission to access the object
                    break;

                case 'storage/canceled':
                    // User canceled the upload
                    break;

                    //  ...

                case 'storage/unknown':
                    // Unknown error occurred, inspect error.serverResponse
                    break;
                }

                d.reject();
            }, function() {
                // Upload completed successfully, now we can get the download URL
                var link = uploadTask.snapshot.downloadURL;
                factory.addPhotoToAlbum (uploader.alb_id, photo_id, link).then(function () {
                    d.resolve();
                });
            });

            return d.promise;
        };

        return factory;
    }]).directive('ngThumb', ['$window', function($window) {
        var helper = {
            support: !!($window.FileReader && $window.CanvasRenderingContext2D),
            isFile: function(item) {
                return angular.isObject(item) && item instanceof $window.File;
            },
            isImage: function(file) {
                var type =  '|' + file.type.slice(file.type.lastIndexOf('/') + 1) + '|';
                return '|jpg|png|jpeg|bmp|gif|'.indexOf(type) !== -1;
            }
        };

        return {
            restrict: 'A',
            template: '<canvas/>',
            link: function(scope, element, attributes) {
                if (!helper.support) return;

                var params = scope.$eval(attributes.ngThumb);

                if (!helper.isFile(params.file)) return;
                if (!helper.isImage(params.file)) return;

                var canvas = element.find('canvas');
                var reader = new FileReader();

                reader.onload = onLoadFile;
                reader.readAsDataURL(params.file);

                function onLoadFile(event) {
                    var img = new Image();
                    img.onload = onLoadImage;
                    img.src = event.target.result;
                }

                function onLoadImage() {
                    var width = params.width || this.width / this.height * params.height;
                    var height = params.height || this.height / this.width * params.width;
                    canvas.attr({ width: width, height: height });
                    canvas[0].getContext('2d').drawImage(this, 0, 0, width, height);
                }
            }
        };
    }]);